


























































import Vue from "vue";
import gql from "graphql-tag";
import InteriorsLink from "./InteriorsLink";
import Headline from "@/components/Headline.vue";
import FormSection from "@/components/FormSection.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import UploadInput from "@/components/form/UploadInput.vue";
import SaveBar from "@/components/form/SaveBar.vue";
import SingleSelectInput from "@/components/form/SingleSelectInput.vue";
import TabsFormSection from "@/components/TabsFormSection.vue";
import Tab from "@/components/form/Tab.vue";
import Head from "@/components/table/Head.vue";
import DefaultCell from "@/components/table/DefaultCell.vue";
import LinkAction from "@/components/LinkAction.vue";

export default Vue.extend({
  apollo: {
    pdb_interiorslinks: {
      // GraphQL Query
      query: gql`
        query pdb_interiorslinks($enginelinkID: bigint!) {
          pdb_interiorslinks(where: { enginelink_id: { _eq: $enginelinkID } }) {
            created
            enginelink_id
            id
            price
            order
            interior_id
            interior {
              name
            }
          }
        }
      `,
      // Reactive variables
      variables() {
        return {
          enginelinkID: this.$route.params.enginelinkID,
        };
      },
      update(data) {
        console.log(data);

        return data.pdb_interiorslinks;
      },
    },
    pdb_interiors: {
      query: gql`
        query pdb_interiors {
          pdb_interiors {
            id
            name
          }
        }
      `,
      update(data) {
        return data.pdb_interiors;
      },
    },
  },
  data() {
    return {
      skipQuery: true,
      pdb_interiorslink: {} as InteriorsLink,
    };
  },
  computed: {
    selectedInteriorID: {
      get(): string {
        return `${this.pdb_interiorslink.interior_id}`;
      },
      set(newVal: string) {
        const newID = parseInt(newVal);
        this.pdb_interiorslink.interior_id = newID;
      },
    },
  },
  methods: {
    store() {
      // clone current model object and remove __typename property which was added by apollo
      console.log("store");

      const objectToStore: InteriorsLink = Object.assign(
        {},
        this.pdb_interiorslink
      );
      delete objectToStore.__typename;
      objectToStore.enginelink_id = parseInt(this.$route.params.enginelinkID);
      console.log(objectToStore);

      // Create new model
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation($objects: [pdb_interiorslinks_insert_input!]!) {
              insert_pdb_interiorslinks(objects: $objects) {
                affected_rows
              }
            }
          `,
          // Parameters
          variables: {
            objects: objectToStore,
          },
        })
        .then((data) => {
          // Result
          console.log(data);
          // this.$router.push("/");
          this.$apollo.queries.pdb_interiorslinks.refetch();
          this.pdb_interiorslink = {};
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input
        });
    },
    remove(id: number) {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation($id: bigint!) {
              delete_pdb_interiorslinks_by_pk(id: $id) {
                id
              }
            }
          `,
          // Parameters
          variables: {
            id: id,
          },
        })
        .then((data) => {
          // Result
          console.log(data);
          // this.$router.push("/");
          this.$apollo.queries.pdb_interiorslinks.refetch();
          this.pdb_interiorslink = {};
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input
        });
    },
    cancel() {
      this.$router.push("/interiors/assign");
    },
  },
  components: {
    Headline,
    FormSection,
    TextInput,
    CheckboxInput,
    UploadInput,
    SaveBar,
    SingleSelectInput,
    NumberInput,
    TabsFormSection,
    Tab,
    Head,
    DefaultCell,
    LinkAction,
  },
});
